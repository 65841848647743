import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Button from '../../components/Button'
import { colors, s, sDesc, sTextGradientBlack } from '../../style'
import alt from '../../content/alt'

import CWB from '../../images/about/certificates/CWB.svg'
import GMPPlus from '../../images/about/certificates/GMP_Plus.svg'
import IFS_Logistics from '../../images/about/certificates/IFS_Logistics.svg'
import IFS_Broker from '../../images/about/certificates/IFS_Broker.svg'
import ISO_9001 from '../../images/about/certificates/ISO_9001.svg'
import ISO_14001 from '../../images/about/certificates/ISO_14001.svg'
import ISO_28000 from '../../images/about/certificates/ISO_28000.svg'
import WSK from '../../images/about/certificates/WSK.svg'
import SQAS from '../../images/about/certificates/SQAS.svg'

const svgs = {
  CWB,
  GMPPlus,
  IFS_Logistics,
  IFS_Broker,
  ISO_9001,
  ISO_14001,
  ISO_28000,
  WSK,
  SQAS,
}

const content = {
  title: {
    en: 'Certificates',
    es: 'Certificados',
    ro: 'Certificate',
  },
  desc: {
    en: `
    Quality certificates are an important element of our organization. Thanks to the certificates we have, you can be sure of high standards both in the areas of service and transport services. Meeting high expectations is what distinguishes us on the market. At the time of the acquisition, the certificates remain valid, and letters requesting updates have been sent to the institutions issuing the following certifications.
    `,
    es: `
    Los certificados de calidad son un elemento importante de nuestra organización. Gracias a los certificados que tenemos, puede estar seguro de los altos estándares tanto en las áreas de servicio como en los servicios de transporte. Cumplir con las altas expectativas es lo que nos distingue en el mercado. En el momento de la adquisición, los certificados permanecen válidos, y se han enviado cartas solicitando actualizaciones a las instituciones que emiten las siguientes certificaciones.
    `,
    ro: `
    Certificatele de calitate sunt un element important al organizației noastre. Datorită certificatelor pe care le deținem, puteți fi siguri de standarde înalte atât în domeniile serviciilor, cât și în serviciile de transport. Îndeplinirea așteptărilor ridicate este ceea ce ne distinge pe piață. La momentul achiziției, certificatele rămân valabile, iar scrisorile care solicită actualizări au fost trimise instituțiilor care emit următoarele certificări.
    `,
  },
  data: [
    {
      title: 'ISO 9001',
      icon: 'ISO_9001',
      desc: {
        pl: 'Międzynarodowa norma ISO 9001 gwarantuje, że nasze procesy operacyjne spełniają rygorystyczne standardy zarządzania jakością. Dzięki temu nasi klienci mogą liczyć na niezawodność, precyzję i ciągłe doskonalenie naszych usług.',
        en: 'The international ISO 9001 standard guarantees that our operational processes meet rigorous quality management standards. This ensures our clients can rely on reliability, precision, and continuous improvement in our services.',
        es: 'La norma ISO 9001 internacional garantiza que nuestros procesos operativos cumplen con estándares estrictos de gestión de calidad. Esto garantiza que nuestros clientes puedan confiar en la fiabilidad, precisión y mejora continua en nuestros servicios.',
        ro: 'Standardul internațional ISO 9001 garantează că procesele noastre operaționale îndeplinesc standarde strict de management a calității. Acesta garantează că clienții noștri pot să se bazeze pe fiabilitate, precizie și îmbunătățiri continue în serviciile noastre.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/iso9001_pl.pdf' },
        { text: { pl: 'EN', en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/iso9001_en.pdf' },
      ],
    },
    {
      title: 'ISO 14001',
      icon: 'ISO_14001',
      desc: {
        pl: 'Certyfikat ISO 14001 potwierdza nasze zaangażowanie w ochronę środowiska i zrównoważony rozwój. Stosujemy ekologiczne rozwiązania w logistyce i optymalizujemy procesy transportowe, minimalizując wpływ na planetę.',
        en: 'The ISO 14001 certification confirms our commitment to environmental protection and sustainable development. We implement eco-friendly solutions in logistics and optimize transportation processes to minimize our impact on the planet.',
        es: 'El certificado ISO 14001 confirma nuestro compromiso con la protección del medio ambiente y el desarrollo sostenible. Implementamos soluciones ecológicas en logística y optimizamos los procesos de transporte para minimizar nuestro impacto en el planeta.',
        ro: 'Certificarea ISO 14001 confirmă angajamentul nostru față de protejarea mediului și dezvoltarea durabilă. Implementăm soluții ecologice în logistica și optimizăm procesele de transport pentru a minimiza impactul asupra planetei.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/iso14001_pl.pdf' },
        { text: { pl: 'EN', en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/iso14001_en.pdf' },
      ],
    },
    {
      title: 'ISO 28000',
      icon: 'ISO_28000',
      desc: {
        pl: 'Norma ISO 28000 zapewnia pełną kontrolę nad bezpieczeństwem w łańcuchu dostaw. Dzięki niej minimalizujemy ryzyko zagrożeń logistycznych, a każda dostawa realizowana jest zgodnie z najwyższymi standardami ochrony.',
        en: 'The ISO 28000 standard ensures full control over security in the supply chain. It allows us to minimize logistical risks, ensuring that every delivery is carried out in accordance with the highest security standards.',
        es: 'El estándar ISO 28000 garantiza un control completo de la seguridad en la cadena de suministro. Nos permite minimizar los riesgos logísticos, asegurando que cada entrega se realice de acuerdo con los estándares de seguridad más altos.',
        ro: 'Standardul ISO 28000 garantează control total asupra securității în lanțul de aprovizionare. Ne permite să reducem riscurile logistice, asigurând că fiecare livrare se efectuează în conformitate cu cele mai înalte standarde de securitate.',
      },
      links: [
        { text: { pl: 'EN', en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/iso28000_en.pdf' },
      ],
    },
    {
      title: 'IFS Broker',
      icon: 'IFS_Broker',
      desc: {
        pl: 'Certyfikat IFS Broker to gwarancja zgodności z międzynarodowymi standardami w zakresie handlu i logistyki produktów spożywczych. Potwierdza on nasze wysokie standardy w obszarze bezpieczeństwa i jakości transportowanych towarów.',
        en: 'The IFS Broker certification guarantees compliance with international standards in the trade and logistics of food products. It confirms our high standards in the area of safety and quality of transported goods.',
        es: 'El certificado IFS Broker garantiza el cumplimiento de estándares internacionales en el comercio y logística de productos alimenticios. Confirma nuestros altos estándares en el área de seguridad y calidad de los bienes transportados.',
        ro: 'Certificarea IFS Broker garantează respectarea standardelor internaționale în comerțul și logistica produselor alimentare. Confirmă standardele noastre ridicate în domeniul siguranței și calității bunurilor transportate.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/ifsbroker_pl.pdf' },
      ],
    },
    {
      title: 'WSK',
      icon: 'WSK',
      desc: {
        pl: 'Posiadamy certyfikat WSK, który potwierdza nasze kompetencje w zakresie obrotu towarami o znaczeniu strategicznym. Dzięki temu zapewniamy pełną zgodność z regulacjami prawnymi dotyczącymi transportu specjalistycznego.',
        en: 'We hold the WSK certification, which confirms our competencies in handling goods of strategic importance. This ensures full compliance with legal regulations related to specialized transportation.',
        es: 'Ten certificado WSK confirma nuestras competencias en el manejo de mercancías de importancia estratégica. Esto garantiza una plena conformidad con las regulaciones legales relacionadas con el transporte especializado.',
        ro: 'Acest certificat WSK confirmă abilitățile noastre în gestionarea bunurilor de importanță strategică. Acesta garantează o conformitate totală cu legislația legală legată de transportul specializat.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/wsk_pl.pdf' },
      ],
    },
    {
      title: 'GMP+ B4',
      icon: 'GMPPlus',
      desc: {
        pl: 'Certyfikat GMP+ to międzynarodowy standard, który zapewnia bezpieczeństwo transportu pasz i komponentów paszowych. Dzięki niemu nasi partnerzy mogą mieć pewność, że przewożone przez nas produkty spełniają rygorystyczne wymagania jakościowe.',
        en: 'The GMP+ certification is an international standard that guarantees the safety of feed transportation and feed components. It provides our partners with confidence that the products we transport meet strict quality requirements.',
        es: 'El certificado GMP+ es un estándar internacional que garantiza la seguridad del transporte de alimentos y componentes de alimentos. Esto proporciona a nuestros socios confianza de que los productos que transportamos cumplen con estrictos requisitos de calidad.',
        ro: 'Certificarea GMP+ este un standard internațional care garantează siguranța transportului de alimente și componente de alimente. Acesta oferă partenerilor noștri încredere că produsele pe care le transportăm îndeplinesc cerințele de calitate strictă.',
      },
      links: [
        { text: { pl: 'PL', en: 'PL', es: 'PL', ro: 'PL' }, link: '/pdf/cert/gmp_pl.pdf' },
        { text: { pl: 'EN', en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/gmp_en.pdf' },
      ],
    },
    {
      title: 'SQAS',
      icon: 'SQAS',
      desc: {
        pl: 'SQAS to europejski standard oceny jakości, bezpieczeństwa i zrównoważonego rozwoju w logistyce produktów chemicznych. Certyfikat ten potwierdza nasze wysokie kompetencje w zakresie przewozu substancji wymagających szczególnej ostrożności.',
        en: 'SQAS is a European standard for assessing quality, safety, and sustainability in the logistics of chemical products. This certification confirms our high expertise in the transport of substances requiring special care.',
        es: 'SQAS es un estándar europeo para evaluar calidad, seguridad y sostenibilidad en la logística de productos químicos. Este certificado confirma nuestras altas competencias en el transporte de sustancias que requieren un cuidado especial.',  
        ro: 'SQAS este un standard european pentru evaluarea calității, siguranței și durabilității în logistica produselor chimice. Acest certificat confirmă abilitățile noastre ridicate în transportul substanțelor care necesită o atenție deosebită.',
      },
      links: [
        { text: { pl: 'EN', en: 'EN', es: 'EN', ro: 'EN' }, link: '/pdf/cert/sqas_en.pdf' },
      ],
    },
  ],
}

const Certificates = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Section top bottom id='certificates'>
        <Headline
          h={2}
          title={content.title[lang]}
          desc={content.desc[lang]}
          large
          gradient
        />
        <div css={sContainer}>
          {content.data.map(({ title, desc, icon, links }, id) => (
            <div css={sCert} key={id}>
              {icon && <img css={sImage} key={id} src={svgs[icon]} alt={title + alt} />}
              <div css={sText}>
                <h3 css={sTitle}>{title}</h3>
                <p css={[sDesc, { fontSize: '0.75rem' }]}>{desc[lang]}</p>
                <div
                  css={{
                    display: 'flex',
                    marginTop: '1.5rem',
                    [s.sm_down]: { justifyContent: 'space-between' },
                  }}>
                  {links?.map(({ text, link }, id) => (
                    <Button
                      noMargin
                      small
                      key={id}
                      link={link}
                      extraCss={{
                        marginRight: '1rem',
                      }}>
                      {text[lang]}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </>
  )
}

const sContainer = {
  display: 'grid',
  marginTop: '4rem',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '2rem',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}

const sCert = {
  backgroundColor: colors.greyLighter,
  display: 'flex',
  [s.xs]: {
    padding: '1rem 2rem 2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
  },
  [s.sm]: { borderRadius: '1rem', padding: '2rem 2rem 2rem 1rem' },
  [s.md_only]: {
    flexDirection: 'column',
    padding: '1rem 2rem 4rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [s.md]: {
    borderRadius: '2rem',
  },
  [s.lg]: { padding: '4rem 2rem' },
}

const sImage = {
  [s.md_down]: {
    flexBasis: '50%',
    maxWidth: '50%',
  },

  [s.sm_only]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },

  [s.lg]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
}

const sText = {
  [s.sm_only]: {
    paddingLeft: '1rem',
  },
  [s.lg]: {
    flexBasis: '75%',
    maxWidth: '75%',
    paddingLeft: '2rem',
  },
}

const sTitle = {
  ...sTextGradientBlack,
  fontSize: '1.5rem',
  marginBottom: '1rem',
  fontWeight: 700,
  width: 'max-content',
}

export default Certificates
